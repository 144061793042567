import fetch from '../fetch';
import type {
  AdminClientDto, UpdateAdminClientDto, CreateClientDto, UpdateClientDto, CreateVirtualCardDto,
} from './dto';
import { ManualRechargeDto } from './dto';

export const createAdminClient = (data: AdminClientDto) => fetch('/signup/admin-client', {
  method: 'POST',
  body: JSON.stringify(data),
});
export const createStaff = (data: AdminClientDto) => fetch('/signup/staff', {
  method: 'POST',
  body: JSON.stringify(data),
});
export const updateAdminClient = (dto: UpdateAdminClientDto) => fetch(`/clients/admin-client/${dto.id}`, {
  method: 'PUT',
  body: JSON.stringify(dto),
});
export const createClient = (dto: CreateClientDto) => fetch('/clients', {
  method: 'POST',
  body: JSON.stringify(dto),
});
export const createVirtualCard = (data: CreateVirtualCardDto) => fetch('/virtual-card', {
  method: 'POST',
  body: JSON.stringify(data),
});
export const updateClient = (dto: UpdateClientDto) => fetch(`/clients/${dto.id}`, {
  method: 'PUT',
  body: JSON.stringify(dto),
});
export const getAdminClient = (entity: string) => fetch(`/clients/admin/entity/${entity}`).then((result) => result.json());
export const getAdminClientById = (id: string) => fetch(`/clients/admin-client/${id}`).then((result) => result.json());
export const getEntityClients = (entity: string) => fetch(`/clients/entity/${entity}`).then((result) => result.json());
export const getClientById = (id: string) => fetch(`/clients/${id}`).then((result) => result.json());
export const checkAdminClientEmail = (email: string) => fetch(`/clients/admin-client/field/email/value/${email}`).then((result) => result.json());
export const checkAdminClientPhone = (phone: string) => fetch(`/clients/admin-client/field/phone/value/${phone}`).then((result) => result.json());
export const manualRecharge = (dto: ManualRechargeDto) => fetch('/virtual-card/recharge', {
  method: 'POST',
  body: JSON.stringify(dto),
});
