import { StyleSheet } from 'react-native';
import { colors, dimensions } from '../../consts';
import { typography } from '../../styles';
import { colorSchema } from '@/consts/colors';

export const Styles = StyleSheet.create({
  progressbarContainer: {
    maxWidth: dimensions.paperWidth / 2,
    width: '100%',
  },
  inputContainer: {
    width: '100%',
  },
  content: {
    ...typography.p2,
    color: colors.newEntity.subtitle,
    marginBottom: 15,
  },
  inputsContainerStep3: {
    maxWidth: dimensions.paperWidth / 2,
    width: '100%',
  },
  discountAmountContainer: {
    width: '42%',
    height: 30,
    justifyContent: 'center'
  },
  discountAmount: {
    ...typography.p3,
    color: colorSchema.PureBlack,
    fontWeight: '700',
  },
  addIconWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    top: 85,
    position: 'absolute',
    left: 20,
    zIndex: 0,
  },
  addIcon: {
    width: 24,
    height: 24,
  },
  add: {
    ...typography.p3,
    fontWeight: '700',
    color: colors.createProduct.add,
  },
});
