import React from 'react';

import { useFonts } from 'expo-font';
import AppLoading from 'expo-app-loading';
import { QueryClient, QueryClientProvider } from 'react-query';
import { PaperProvider } from 'react-native-paper';
import { Main } from './src/navigation/main';
import theme from '@/config/theme';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    }
  }
});

export const App = () => {
  const [fontsLoaded] = useFonts({
    // eslint-disable-next-line global-require
    'Roboto-Regular': require('./src/assets/fonts/Roboto-Regular.ttf'),
    // eslint-disable-next-line global-require
    'Roboto-Bold': require('./src/assets/fonts/Roboto-Bold.ttf'),
    // eslint-disable-next-line global-require
    'Roboto-Medium': require('./src/assets/fonts/Roboto-Medium.ttf'),
    // eslint-disable-next-line global-require
    'Roboto-Light': require('./src/assets/fonts/Roboto-Light.ttf'),
  });

  if (!fontsLoaded) {
    return <AppLoading />;
  }

  return (
    <PaperProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Main />
      </QueryClientProvider>
    </PaperProvider>
  );
};

export default App;
