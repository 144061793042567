import React from 'react';

import { ScrollView, Text, View } from 'react-native';
import { MFullWidthButton, MTextInput } from 'menupass-ui';
import { InvitationsCreateTypes } from '@/pages/invitationscreate/InvitationsCreate.types';
import { Header } from '@/components/header';
import { Subheader } from '@/components/subheader';
import { useInvitationsCreate } from '@/pages/invitationscreate/useInvitationsCreate';
import { Routes } from '@/navigation/routes';
import { Container } from '@/components/container';
import locale from '@/internationalization';
import { EntitiesPageTitle } from '@/components/entitiespagetitle';
import { Paper } from '@/components/paper';
import { InvitationsTabs } from '@/components/invitationstabs';
import { PaperColumns } from '@/styles/common/PaperColumns';
import { PageStyles } from '@/styles/common/PageStyles';
import { colors, Images } from '@/consts';
import { Styles } from '@/pages/invitationscreate/InvitationsCreate.styles';
import { SearchableInput } from '@/components/searchableinput/SearchableInput';
import { ErrorHandler } from '../../components/errorhandler';

const InvitationsCreate = ({
  route: {
    params: { entity, id },
  },
}: InvitationsCreateTypes) => {
  const {
    adminData,
    entityData,
    name,
    email,
    phone,
    typeList,
    typeLabel,
    emailChange,
    emailError,
    loading,
    onSubmit,
    goBack,
    setTypeLabel,
    setName,
    setPhone,
    setType,
    APIErrorMessage,
    hasAPIError,
  } = useInvitationsCreate(entity, id || '');
  return (
    <>
      <Header username={adminData?.name} role={adminData?.role} />
      <Subheader
        role={adminData?.adminType?.type}
        entityName={entityData?.name}
        entityId={entity}
        activeScreen={Routes.InvitationsList}
      />
      <Container>
        <EntitiesPageTitle
          title={locale.t('invitations')}
          exportPdf={() => console.log(null)}
          exportCsv={() => console.log(null)}
        />
        <Paper disableInternalPadding bgColor="transparent" hasSubmenu>
          <InvitationsTabs list={false} createIndividual createGeneral={false} entityId={entity} />
          <View style={PaperColumns.tableContainer}>
            <ScrollView>
              <View style={PaperColumns.container}>
                <View style={PaperColumns.column}>
                  <View style={PageStyles.imageWrapper}>
                    <img
                      src={Images.InvitationIcon}
                      alt={locale.t('imagesAlt.new')}
                    />
                  </View>
                  <Text style={PageStyles.subtitle}>
                    {locale.t('new_invitation')}
                  </Text>
                  <Text style={PageStyles.content}>
                    {locale.t('new_invitation_content')}
                  </Text>
                </View>
                <View style={PaperColumns.column}>
                  <View style={PageStyles.subtitleHeader}>
                    <Text style={[PageStyles.subtitle, { marginBottom: 0 }]}>
                      {locale.t('invitation_info')}
                    </Text>
                    <View style={PageStyles.headerButtons}>
                      <View style={PageStyles.buttonsWrapper}>
                        <MFullWidthButton
                          label={locale.t('cancel')}
                          bgColor={colors.newEntity.entitiesBtn}
                          labelColor={colors.newEntity.entitiesBtnTitle}
                          onPress={goBack}
                          height={42}
                          fontSize={14}
                        />
                      </View>
                      <View style={PageStyles.buttonsWrapper}>
                        <MFullWidthButton
                          label={id ? locale.t('update_invite') : locale.t('send_invite')}
                          onPress={onSubmit}
                          disabled={
                            name.trim() === ''
                            || email.trim() === ''
                            || emailError
                            || phone.trim() === ''
                            || typeLabel.trim() === ''
                            || loading
                          }
                          height={42}
                          fontSize={14}
                        />
                      </View>
                    </View>
                  </View>
                  <View style={Styles.inputContainer}>
                    <Text style={Styles.content}>
                      {locale.t('invitations_subtitle')}
                    </Text>
                    {hasAPIError && (
                    <ErrorHandler message={APIErrorMessage} />
                    )}
                    <View style={PageStyles.formRow}>
                      <View style={[PageStyles.inputWrapper, { width: '46%' }]}>
                        <MTextInput
                          label={locale.t('name')}
                          placeholder={locale.t('name_placeholder')}
                          value={name}
                          onChangeText={setName}
                          height={42}
                          borderRadius={14}
                        />
                      </View>
                      <View style={[PageStyles.inputWrapper, { width: '46%' }]}>
                        <MTextInput
                          label={locale.t('email')}
                          placeholder={locale.t('email_placeholder')}
                          value={email}
                          onChangeText={emailChange}
                          hasError={emailError}
                          height={42}
                          borderRadius={14}
                        />
                      </View>
                    </View>
                    <View style={PageStyles.formRow}>
                      <View style={[PageStyles.inputWrapper, { width: '46%' }]}>
                        <MTextInput
                          label={locale.t('phone')}
                          placeholder={locale.t('phone_placeholder')}
                          value={phone}
                          onChangeText={setPhone}
                          mask="(00) 00000-0000"
                          height={42}
                          borderRadius={14}
                        />
                      </View>
                      <View style={[PageStyles.inputWrapper, { width: '46%' }]}>
                        <SearchableInput
                          value={typeLabel}
                          onChange={(inputValue) => setTypeLabel(inputValue)}
                          label={locale.t('type')}
                          data={typeList}
                          onSelect={(item) => {
                            setType(item.value);
                            setTypeLabel(item.label);
                          }}
                        />
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </ScrollView>
          </View>
        </Paper>
      </Container>
    </>
  );
};

export default InvitationsCreate;
