import React from 'react';
import {
  ScrollView, Text, TouchableOpacity, View,
} from 'react-native';
import {
  MFullWidthButton,
  MRadioButton,
  MSelect,
  MTextInput,
} from 'menupass-ui';
import { Header } from '../../components/header';
import { Container } from '../../components/container';
import locale from '../../internationalization';
import { PageTitle } from '../../components/pagetitle';
import { Paper } from '@/components/paper';
import { colors, dimensions, Images } from '@/consts';
import { ProgressBar } from '../../components/progressbar';
import { PaperColumns } from '@/styles/common/PaperColumns';
import { Styles } from './newEntity.styles';
import { useNewEntity } from './NewEntity.functions';
import { PageStyles } from '@/styles/common/PageStyles';
import { UploadComponent } from '../../components/uploadcomponent';
import { ErrorHandler } from '../../components/errorhandler';
import { AccountTypeSelect, NewEntityTypes } from './NewEntity.types';
import { colorSchema } from '@/consts/colors';
import CancelSubscriptionModal from '../../components/cancelsubscriptionmodal/CancelSubscriptionModal';

export const NewEntity = ({ route: { params } }: NewEntityTypes) => {
  const {
    onChangePlan,
    goToEntities,
    goToAddPayment,
    onRadioChange,
    onImagePick,
    submit,
    goToStep1,
    goToStep2,
    goToStep3,
    goToStep4,
    goToStep5,
    setComplement,
    setNumber,
    setZipCode,
    onSearchZipCode,
    phone,
    logo,
    setPhone,
    isEditing,
    setState,
    setName,
    setStreet,
    setCity,
    complement,
    city,
    street,
    state,
    number,
    zipCode,
    name,
    nameEmpresa,
    commercialName,
    identity,
    responsibleName,
    responsibleIdentity,
    responsiblePhone,
    email,
    techName,
    techIdentity,
    techEmail,
    techPhone,
    bankCode,
    accountTypeCode,
    bankAgency,
    bankAgencyDigit,
    bankAccount,
    bankAccountDigit,
    setBankCode,
    setAccountTypeCode,
    setBankAgency,
    setBankAgencyDigit,
    setBankAccount,
    setBankAccountDigit,
    setNameEmpresa,
    setCommercialName,
    setIdentity,
    setResponsibleName,
    setResponsibleIdentity,
    setResponsiblePhone,
    emailError,
    setTechName,
    setTechIdentity,
    setTechPhone,
    planRecurrence,
    adminData,
    cardSelected,
    plansSelect,
    planSelected,
    planSelectedObject,
    cardsSelect,
    planRecurrenceRadio,
    step1,
    step2,
    step3,
    step4,
    step5,
    loading,
    imageCache,
    hasAPIError,
    APIErrorMessage,
    entityData,
    emailChange,
    emailTechChange,
    onCancelPlan,
    isCancelSubscriptionModalOpened,
    onCloseModal,
    onOpenModal,
    acceptanceTerm,
    acceptanceTermLabel,
    onTermChange,
    openAbaTerm,
  } = useNewEntity(params?.id || '');
  return (
    <>
      <Header username={adminData?.name} role={adminData?.role} />
      <Container>
        <PageTitle
          title={isEditing ? entityData?.name : locale.t('newEntity.title')}
          component={(
            <View style={Styles.entitiesContainer}>
              <MFullWidthButton
                label={locale.t('newEntity.entities')}
                bgColor={colors.newEntity.entitiesBtn}
                labelColor={colors.newEntity.entitiesBtnTitle}
                onPress={() => goToEntities()}
                height={42}
                fontSize={14}
              />
            </View>
          )}
        />
        <Paper disableInternalPadding>
          <View style={[PaperColumns.tableContainer]}>
            <ScrollView>
              <View style={PaperColumns.container}>
                <View style={PaperColumns.column}>
                  <View style={Styles.imageWrapper}>
                    <img
                      src={Images.EntityIcon}
                      alt={locale.t('imagesAlt.mewEntity')}
                    />
                  </View>
                  <Text style={Styles.subtitle}>
                    {isEditing
                      ? locale.t('newEntity.edit')
                      : locale.t('newEntity.new')}
                  </Text>
                  <Text style={Styles.content}>
                    {locale.t('newEntity.content')}
                  </Text>
                </View>
                <View style={PaperColumns.column}>
                  <View style={Styles.progressbarContainer}>
                    <ProgressBar
                      width={dimensions.paperWidth / 2 - 40}
                      step1={step1}
                      step2={step2}
                      step3={step3}
                      step4={step4}
                      step5={step5}
                      steps={5}
                    />
                  </View>
                  <View style={Styles.subtitlePlansHeader}>
                    {step1 && !step2 && !step3 && !step4 && !step5 && (
                      <Text style={Styles.subtitleRegister}>
                        {locale.t('newEntity.plans')}
                      </Text>
                    )}
                    {step1 && step2 && !step3 && !step4 && !step5 && (
                      <Text style={Styles.subtitleRegister}>
                        {locale.t('newEntity.basic')}
                      </Text>
                    )}
                    {step1 && step2 && step3 && !step4 && !step5 && (
                      <Text style={Styles.subtitleRegister}>
                        {locale.t('newEntity.subAccount')}
                      </Text>
                    )}
                    {step1 && step2 && step3 && step4 && !step5 && (
                      <Text style={Styles.subtitleRegister}>
                        {locale.t('newEntity.bank')}
                      </Text>
                    )}
                    {step1 && step2 && step3 && step4 && step5 && (
                      <Text style={Styles.subtitleRegister}>
                        {locale.t('newEntity.logo')}
                      </Text>
                    )}
                    <View style={Styles.subtitlePlansHeaderButtons}>
                      <View style={Styles.subtitlePlansHeaderButtonsWrapper}>
                        {step1 && !step2 && !step3 && !step4 && !step5 && (
                          <MFullWidthButton
                            label={locale.t('newEntity.cancel')}
                            bgColor={colors.newEntity.entitiesBtn}
                            labelColor={colors.newEntity.entitiesBtnTitle}
                            onPress={goToEntities}
                            height={42}
                            fontSize={14}
                          />
                        )}
                        {step1 && step2 && !step3 && !step4 && !step5 && (
                          <MFullWidthButton
                            label={locale.t('newEntity.back')}
                            bgColor={colors.newEntity.entitiesBtn}
                            labelColor={colors.newEntity.entitiesBtnTitle}
                            onPress={goToStep1}
                            height={42}
                            fontSize={14}
                          />
                        )}
                        {step1 && step2 && step3 && !step4 && !step5 && (
                          <MFullWidthButton
                            label={locale.t('newEntity.back')}
                            bgColor={colors.newEntity.entitiesBtn}
                            labelColor={colors.newEntity.entitiesBtnTitle}
                            onPress={goToStep2}
                            height={42}
                            fontSize={14}
                          />
                        )}
                        {step1 && step2 && step3 && step4 && !step5 && (
                          <MFullWidthButton
                            label={locale.t('newEntity.back')}
                            bgColor={colors.newEntity.entitiesBtn}
                            labelColor={colors.newEntity.entitiesBtnTitle}
                            onPress={goToStep3}
                            height={42}
                            fontSize={14}
                          />
                        )}
                        {step1 && step2 && step3 && step4 && step5 && (
                          <MFullWidthButton
                            label={locale.t('newEntity.back')}
                            bgColor={colors.newEntity.entitiesBtn}
                            labelColor={colors.newEntity.entitiesBtnTitle}
                            onPress={goToStep4}
                            height={42}
                            fontSize={14}
                          />
                        )}
                      </View>
                      <View style={Styles.subtitlePlansHeaderButtonsWrapper}>
                        {step1 && !step2 && !step3 && !step4 && !step5 && (
                          <MFullWidthButton
                            label={locale.t('newEntity.next')}
                            disabled={
                              planSelected === '' || cardSelected === '' || acceptanceTerm[0]?.value === ''
                            }
                            onPress={goToStep2}
                            height={42}
                            fontSize={14}
                          />
                        )}
                        {step1 && step2 && !step3 && !step4 && !step5 && (
                          <MFullWidthButton
                            label={locale.t('newEntity.next')}
                            disabled={
                              zipCode.trim() === ''
                              || name.trim() === ''
                              || state.trim() === ''
                              || city.trim() === ''
                              || street.trim() === ''
                              || phone.trim() === ''
                              || number.trim() === ''
                            }
                            onPress={goToStep3}
                            height={42}
                            fontSize={14}
                          />
                        )}
                        {step1 && step2 && step3 && !step4 && !step5 && (
                          <MFullWidthButton
                            label={locale.t('newEntity.next')}
                            disabled={
                              nameEmpresa.trim() === ''
                              || commercialName.trim() === ''
                              || identity.trim() === ''
                              || responsibleName.trim() === ''
                              || responsibleIdentity.trim() === ''
                              || responsiblePhone.trim() === ''
                              || email.trim() === ''
                              || techName.trim() === ''
                              || techIdentity.trim() === ''
                              || techEmail.trim() === ''
                              || techPhone.trim() === ''
                              || emailError
                            }
                            onPress={goToStep4}
                            height={42}
                            fontSize={14}
                          />
                        )}
                        {step1 && step2 && step3 && step4 && !step5 && (
                          <MFullWidthButton
                            label={locale.t('newEntity.next')}
                            disabled={
                              bankCode.trim() === ''
                              || accountTypeCode.trim() === ''
                              || bankAgency.trim() === ''
                              || bankAccount.trim() === ''
                              || bankAccountDigit.trim() === ''
                            }
                            onPress={goToStep5}
                            height={42}
                            fontSize={14}
                          />
                        )}
                        {step1 && step2 && step3 && step4 && step5 && (
                          <MFullWidthButton
                            label={locale.t('newEntity.save')}
                            disabled={
                              ((loading || imageCache.trim() === '')
                                && !isEditing)
                              || loading
                            }
                            onPress={submit}
                            height={42}
                            fontSize={14}
                          />
                        )}
                      </View>
                    </View>
                  </View>
                  {hasAPIError && <ErrorHandler message={APIErrorMessage} />}
                  {step1 && !step2 && !step3 && !step4 && !step5 && (
                    <>
                      <View style={Styles.inputsContainerStep1}>
                        <Text style={Styles.planContent}>
                          {locale.t('newEntity.planContent')}
                        </Text>
                        <View style={Styles.inputWrapper}>
                          <MSelect
                            label={locale.t('newEntity.plan')}
                            values={plansSelect}
                            selectedValue={planSelected}
                            onValueChange={onChangePlan}
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                        <View style={Styles.inputWrapper}>
                          <MSelect
                            label={locale.t('newEntity.card')}
                            values={cardsSelect}
                            selectedValue={cardSelected}
                            onValueChange={() => null}
                            placeholder={locale.t('newEntity.cardPlaceholder')}
                            enabled={cardsSelect[0]?.value !== ''}
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                      </View>
                      <View>
                        {cardsSelect[0]?.value === '' && (
                          <View style={Styles.addPaymentWrapper}>
                            <MFullWidthButton
                              onPress={goToAddPayment}
                              label={locale.t('newEntity.addPayment')}
                              height={42}
                              fontSize={14}
                            />
                          </View>
                        )}
                        <View style={Styles.planWrapper}>
                          <Text style={Styles.planTitle}>
                            {planSelectedObject?.name}
                          </Text>
                          <View style={Styles.radioWrapper}>
                            <MRadioButton
                              items={planRecurrenceRadio}
                              onChangeValue={onRadioChange}
                              flexDirection="row"
                              side="left"
                              value={planRecurrence}
                              style={Styles.radio}
                            />
                          </View>
                          <View style={Styles.cancelWrapper}>
                            <MFullWidthButton
                              label={locale.t('newEntity.cancelPlan')}
                              height={36}
                              bgColor={colorSchema.Gray2}
                              labelColor={colorSchema.PureBlack}
                              fontSize={12}
                              onPress={onOpenModal}
                            />
                          </View>
                        </View>
                        <View>
                          <TouchableOpacity
                            onPress={openAbaTerm}
                          >
                            <Text style={Styles.planSubTitle}>
                              {locale.t('newEntity.LinkAcceptanceTerm')}
                            </Text>
                          </TouchableOpacity>
                          <MRadioButton
                            items={acceptanceTerm}
                            onChangeValue={onTermChange}
                            flexDirection="row"
                            side="left"
                            value={acceptanceTermLabel}
                            style={Styles.radio}
                          />
                        </View>
                      </View>
                    </>
                  )}
                  {step1 && step2 && !step3 && !step4 && !step5 && (
                    <View style={Styles.inputsContainerStep2}>
                      <Text style={Styles.planContent}>
                        {locale.t('newEntity.basicContent')}
                      </Text>
                      <View style={PageStyles.formRow}>
                        <View
                          style={[PageStyles.inputWrapper, { width: '70%' }]}
                        >
                          <MTextInput
                            label={locale.t('newEntity.form.name')}
                            value={name}
                            onChangeText={setName}
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                        <View
                          style={[PageStyles.inputWrapper, { width: '25%' }]}
                        >
                          <MTextInput
                            label={locale.t('newEntity.form.phone')}
                            value={phone}
                            onChangeText={setPhone}
                            mask="(00) 00000-0000"
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                      </View>
                      <View style={Styles.divider} />
                      <View style={[PageStyles.formRow, { width: '100%' }]}>
                        <View
                          style={[PageStyles.inputWrapper, { width: '25%' }]}
                        >
                          <MTextInput
                            label={locale.t('newEntity.form.cep')}
                            onChangeText={setZipCode}
                            value={zipCode}
                            mask="00000-000"
                            onBlur={onSearchZipCode}
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                        <View
                          style={[PageStyles.inputWrapper, { width: '25%' }]}
                        >
                          <MTextInput
                            label={locale.t('newEntity.form.state')}
                            onChangeText={setState}
                            value={state}
                            maxLength={2}
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                        <View
                          style={[PageStyles.inputWrapper, { width: '40%' }]}
                        >
                          <MTextInput
                            label={locale.t('newEntity.form.city')}
                            onChangeText={setCity}
                            value={city}
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                      </View>
                      <View style={[PageStyles.formRow, { width: '100%' }]}>
                        <View
                          style={[PageStyles.inputWrapper, { width: '50%' }]}
                        >
                          <MTextInput
                            label={locale.t('newEntity.form.street')}
                            onChangeText={setStreet}
                            value={street}
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                        <View
                          style={[PageStyles.inputWrapper, { width: '20%' }]}
                        >
                          <MTextInput
                            label={locale.t('newEntity.form.number')}
                            onChangeText={setNumber}
                            value={number}
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                        <View
                          style={[PageStyles.inputWrapper, { width: '20%' }]}
                        >
                          <MTextInput
                            label={locale.t('newEntity.form.complement')}
                            onChangeText={setComplement}
                            value={complement}
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                      </View>
                    </View>
                  )}
                  {step1 && step2 && step3 && !step4 && !step5 && (
                    <View style={Styles.inputsContainerStep2}>
                      <Text style={Styles.planContent}>
                        {locale.t('newEntity.subAccountContent')}
                      </Text>
                      {!isEditing && (
                        <View style={PageStyles.formRow}>
                          <View
                            style={[PageStyles.inputWrapper, { width: '25%' }]}
                          >
                            <MTextInput
                              label={locale.t('newEntity.formEntityInfoLegal.name')}
                              value={nameEmpresa}
                              onChangeText={setNameEmpresa}
                              height={42}
                              borderRadius={14}
                            />
                          </View>
                          <View
                            style={[PageStyles.inputWrapper, { width: '35%' }]}
                          >
                            <MTextInput
                              label={locale.t('newEntity.formEntityInfoLegal.commercialName')}
                              value={commercialName}
                              onChangeText={setCommercialName}
                              height={42}
                              borderRadius={14}
                            />
                          </View>
                          <View
                            style={[PageStyles.inputWrapper, { width: '35%' }]}
                          >
                            <MTextInput
                              label={locale.t('newEntity.formEntityInfoLegal.identity')}
                              value={identity}
                              onChangeText={setIdentity}
                              mask="00.000.000/0000-00"
                              height={42}
                              borderRadius={14}
                            />
                          </View>
                        </View>
                      )}
                      <View style={[PageStyles.formRow, { width: '100%' }]}>
                        {!isEditing && (
                        <>
                          <View
                            style={[PageStyles.inputWrapper, { width: '35%' }]}
                          >
                            <MTextInput
                              label={locale.t('newEntity.formEntityInfoLegal.responsibleName')}
                              onChangeText={setResponsibleName}
                              value={responsibleName}
                              height={42}
                              borderRadius={14}
                            />
                          </View>
                          <View
                            style={[PageStyles.inputWrapper, { width: '30%' }]}
                          >
                            <MTextInput
                              label={locale.t('newEntity.formEntityInfoLegal.responsibleIdentity')}
                              onChangeText={setResponsibleIdentity}
                              value={responsibleIdentity}
                              mask="000.000.000-00"
                              height={42}
                              borderRadius={14}
                            />
                          </View>
                        </>
                        )}
                        <View
                          style={[PageStyles.inputWrapper, { width: '30%' }]}
                        >
                          <MTextInput
                            label={locale.t('newEntity.formEntityInfoLegal.responsiblePhone')}
                            onChangeText={setResponsiblePhone}
                            value={responsiblePhone}
                            mask="(00) 00000-0000"
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                      </View>
                      <View style={Styles.divider} />
                      {!isEditing && (
                        <View style={[PageStyles.formRow, { width: '100%' }]}>
                          <View
                            style={[PageStyles.inputWrapper, { width: '40%' }]}
                          >
                            <MTextInput
                              label={locale.t('newEntity.formEntityInfoLegal.email')}
                              onChangeText={emailChange}
                              hasError={emailError}
                              keyboardType="email-address"
                              value={email}
                              height={42}
                              borderRadius={14}
                            />
                          </View>
                          <View
                            style={[PageStyles.inputWrapper, { width: '40%' }]}
                          >
                            <MTextInput
                              label={locale.t('newEntity.formEntityInfoLegal.techEmail')}
                              value={techEmail}
                              onChangeText={emailTechChange}
                              hasError={emailError}
                              keyboardType="email-address"
                              height={42}
                              borderRadius={14}
                            />
                          </View>
                        </View>
                      )}

                      <View style={[PageStyles.formRow, { width: '100%' }]}>
                        {!isEditing && (
                        <>
                          <View
                            style={[PageStyles.inputWrapper, { width: '30%' }]}
                          >
                            <MTextInput
                              label={locale.t('newEntity.formEntityInfoLegal.techName')}
                              onChangeText={setTechName}
                              value={techName}
                              height={42}
                              borderRadius={14}
                            />
                          </View>
                          <View
                            style={[PageStyles.inputWrapper, { width: '30%' }]}
                          >
                            <MTextInput
                              label={locale.t('newEntity.formEntityInfoLegal.techIdentity')}
                              onChangeText={setTechIdentity}
                              value={techIdentity}
                              height={42}
                              mask="000.000.000-00"
                              borderRadius={14}
                            />
                          </View>
                        </>
                        )}
                        <View
                          style={[PageStyles.inputWrapper, { width: '35%' }]}
                        >
                          <MTextInput
                            label={locale.t('newEntity.formEntityInfoLegal.techPhone')}
                            onChangeText={setTechPhone}
                            value={techPhone}
                            mask="(00) 00000-0000"
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                      </View>
                    </View>
                  )}
                  {step1 && step2 && step3 && step4 && !step5 && (
                    <View style={Styles.inputsContainerStep2}>
                      <Text style={Styles.planContent}>
                        {locale.t('newEntity.bankContent')}
                      </Text>
                      <View style={PageStyles.formRow}>
                        <View
                          style={[PageStyles.inputWrapper, { width: '25%' }]}
                        >
                          <MTextInput
                            label={locale.t('newEntity.formEntityBankData.bank')}
                            value={bankCode}
                            onChangeText={setBankCode}
                            height={42}
                            maxLength={3}
                            borderRadius={14}
                          />
                        </View>
                        <View
                          style={[PageStyles.inputWrapper, { width: '25%' }]}
                        >
                          <MSelect
                            onValueChange={
                              (value: string) => setAccountTypeCode(value)
                            }
                            selectedValue={accountTypeCode}
                            values={AccountTypeSelect}
                            label={locale.t('newEntity.formEntityBankData.accountType')}
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                        <View
                          style={[PageStyles.inputWrapper, { width: '25%' }]}
                        >
                          <MTextInput
                            label={locale.t('newEntity.formEntityBankData.bankAgency')}
                            value={bankAgency}
                            onChangeText={setBankAgency}
                            height={42}
                            maxLength={50}
                            borderRadius={14}
                          />
                        </View>
                      </View>
                      <View style={[PageStyles.formRow, { width: '100%' }]}>
                        <View
                          style={[PageStyles.inputWrapper, { width: '35%' }]}
                        >
                          <MTextInput
                            label={locale.t('newEntity.formEntityBankData.bankAgencyDigit')}
                            onChangeText={setBankAgencyDigit}
                            value={bankAgencyDigit}
                            height={42}
                            maxLength={1}
                            borderRadius={14}
                          />
                        </View>
                        <View
                          style={[PageStyles.inputWrapper, { width: '25%' }]}
                        >
                          <MTextInput
                            label={locale.t('newEntity.formEntityBankData.bankAccount')}
                            onChangeText={setBankAccount}
                            value={bankAccount}
                            height={42}
                            maxLength={50}
                            borderRadius={14}
                          />
                        </View>
                        <View
                          style={[PageStyles.inputWrapper, { width: '25%' }]}
                        >
                          <MTextInput
                            label={locale.t('newEntity.formEntityBankData.bankAccountDigit')}
                            onChangeText={setBankAccountDigit}
                            value={bankAccountDigit}
                            height={42}
                            maxLength={1}
                            borderRadius={14}
                          />
                        </View>
                      </View>
                    </View>
                  )}
                  {step1 && step2 && step3 && step4 && step5 && (
                    <View style={Styles.inputsContainerStep3}>
                      <Text style={Styles.planContent}>
                        {locale.t('imageContent')}
                      </Text>
                      <View>
                        <UploadComponent
                          onFileSelect={onImagePick}
                          uri={imageCache || logo}
                        />
                      </View>
                    </View>
                  )}
                </View>
              </View>
            </ScrollView>
          </View>
        </Paper>
      </Container>
      {isCancelSubscriptionModalOpened && (
        <CancelSubscriptionModal
          content={locale.t('confirmationContent')}
          title={locale.t('confirmation')}
          onClose={onCloseModal}
          onConfirm={onCancelPlan}
          buttonTitle={locale.t('cancelSubscription')}
        />
      )}
    </>
  );
};

export default NewEntity;
