import { useMutation, useQuery } from 'react-query';
import {
  getAdmin, getAdminId, updateAdmin, updateAdminPaymentAddress, updatePassword,
} from './requests';

export const useGetAdminId = () => useQuery('adminId', getAdminId);
export const useGetAdmin = (id: string, isEnabled: boolean) => useQuery(['admin', id], () => getAdmin(id), { enabled: isEnabled });
export const useUpdateAdminPaymentAddress = () => useMutation(updateAdminPaymentAddress);
export const useUpdateAdmin = () => useMutation(updateAdmin);
export const useUpdatePassword = () => useMutation(updatePassword);
