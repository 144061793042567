import { useState } from 'react';
import { emailIsValid } from '@/utils/strUtils';
import { useNavigate } from '@/navigation/routes/hooks/useNavigate';
import { Routes } from '@/navigation/routes';
import { useAdminLogin } from '@/api/login';
import locale from '@/internationalization';
import { LocalStorage } from '@/services/localStorage';
import { ADMIN } from '@/consts/storageKeys';

export const useLogin = () => {
  const { navigate } = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [APIErrorMessage, setAPIErrorMessage] = useState('');
  const [hasAPIError, setHasAPIError] = useState(false);

  const mutator = useAdminLogin();

  const emailChange = (e: string) => {
    setEmail(e);
    setEmailError(emailIsValid(e));
  };

  const goToRegister = () => {
    navigate(Routes.Register);
  };

  const goToRecoveryPassword = () => {
    navigate(Routes.RecoveryPassword);
  };

  const login = async () => {
    if (email.trim() && password.trim()) {
      try {
        const result = await mutator.mutateAsync({
          username: email,
          password,
        });

        if (result.status !== 200) {
          setHasAPIError(true);
          setAPIErrorMessage(locale.t('errors.401'));
          return;
        }

        const data = await result.json();
        await LocalStorage.save(ADMIN, data);
        window.location.reload();
      } catch (e) {
        setHasAPIError(true);
        setAPIErrorMessage(locale.t('errors.500'));
      }
    }
  };

  return {
    emailChange,
    goToRegister,
    login,
    emailError,
    email,
    password,
    setPassword,
    APIErrorMessage,
    hasAPIError,
    goToRecoveryPassword,
  };
};

export default useLogin;
