import React from 'react';
import { ScrollView, Text, View } from 'react-native';
import { MFullWidthButton, MRadioButton, MTextInput } from 'menupass-ui';
import { Header } from '../../components/header';
import { Container } from '../../components/container';
import locale from '../../internationalization';
import { PageTitle } from '../../components/pagetitle';
import { Paper } from '../../components/paper';
import { PaymentTabs } from '../../components/paymenttabs';
import { colors, Images } from '../../consts';
import { PaperColumns } from '../../styles/common/PaperColumns';
import { PageStyles } from '../../styles/common/PageStyles';
import { usePaymentData } from './PaymentData.functions';
import { ErrorHandler } from '../../components/errorhandler';
import { SuccessHandler } from '../../components/successhandler';
import { PaymentDataTypes } from './PaymentData.types';
import { Styles } from './paymentData.styles';

export const PaymentDataPage = ({ route: { params } }: PaymentDataTypes) => {
  const {
    name,
    complement,
    number,
    city,
    street,
    zipCode,
    nationalId,
    addressHolderName,
    state,
    hasAPIError,
    apiSuccessMessage,
    APIErrorMessage,
    APISuccess,
    loading,
    forwardBtnText,
    nationalIdRadio,
    nationalIdMask,
    adminData,
    nationalIdHasError,
    setComplement,
    setStreet,
    setNumber,
    setCity,
    setState,
    setAddressHolderName,
    goBack,
    submit,
    onChangeNationalId,
    onChangeZipCode,
    onSearchZipCode,
    onChangeNationalIdRadio,
  } = usePaymentData(params?.flow);
  return (
    <>
      <Header username={name} role={adminData?.role} />
      <Container>
        <PageTitle title={locale.t('paymentData.pageTitle')} />
        <Paper disableInternalPadding bgColor="transparent">
          <PaymentTabs
            paymentCards={false}
            paymentAddCard={false}
            paymentsData
            payments={false}
          />
          <View style={PaperColumns.tableContainer}>
            <ScrollView>
              <View style={PaperColumns.container}>
                <View style={PaperColumns.column}>
                  <View style={PageStyles.imageWrapper}>
                    <img src={Images.PaymentDataIcon} alt={locale.t('imagesAlt.paymentData')} />
                  </View>
                  <Text style={PageStyles.subtitle}>{locale.t('paymentData.subtitle')}</Text>
                  <Text style={PageStyles.content}>{locale.t('paymentData.content')}</Text>
                </View>
                <View style={PaperColumns.column}>
                  <View style={PageStyles.subtitleHeader}>
                    <View />
                    <View style={PageStyles.headerButtons}>
                      <View style={PageStyles.buttonsWrapper}>
                        <MFullWidthButton
                          label={locale.t('paymentData.cancel')}
                          bgColor={colors.newEntity.entitiesBtn}
                          labelColor={colors.newEntity.entitiesBtnTitle}
                          onPress={goBack}
                          height={42}
                          fontSize={14}
                        />
                      </View>
                      <View style={PageStyles.buttonsWrapper}>
                        <MFullWidthButton
                          label={forwardBtnText}
                          disabled={
                            addressHolderName.trim() === ''
                            || nationalId.trim() === ''
                            || zipCode.trim() === ''
                            || state.trim() === ''
                            || city.trim() === ''
                            || street.trim() === ''
                            || number.trim() === ''
                            || nationalIdHasError
                            || loading
                          }
                          onPress={submit}
                          height={42}
                          fontSize={14}
                        />
                      </View>
                    </View>
                  </View>
                  {hasAPIError && (
                    <ErrorHandler message={APIErrorMessage} />
                  )}
                  {APISuccess && (
                    <SuccessHandler message={apiSuccessMessage} />
                  )}
                  <View>
                    <Text style={PageStyles.subTitleForm}>{locale.t('paymentData.form.content')}</Text>
                    <View style={PageStyles.formRow}>
                      <View style={[PageStyles.inputWrapper, { width: '46%' }]}>
                        <MTextInput
                          label={locale.t('paymentData.form.name')}
                          onChangeText={setAddressHolderName}
                          value={addressHolderName}
                          height={42}
                          borderRadius={14}
                        />
                      </View>
                      <View style={[PageStyles.inputWrapper, { width: '46%' }]}>
                        <View style={Styles.radioWrapper}>
                          <MRadioButton
                            items={nationalIdRadio.current}
                            onChangeValue={onChangeNationalIdRadio}
                            flexDirection="row"
                            side="left"
                            value={nationalIdMask}
                            style={Styles.radio}
                          />
                        </View>
                      </View>
                    </View>
                    <View style={[PageStyles.inputWrapper, { width: '46%' }]}>
                      <MTextInput
                        label={locale.t('paymentData.form.cnpj')}
                        onChangeText={onChangeNationalId}
                        value={nationalId}
                        height={42}
                        borderRadius={14}
                        hasError={nationalIdHasError}
                      />
                    </View>
                    <View />
                    <View style={PageStyles.formRow}>
                      <View style={[PageStyles.formRow, { width: '46%' }]}>
                        <View style={[PageStyles.inputWrapper, { width: '48%' }]}>
                          <MTextInput
                            label={locale.t('paymentData.form.cep')}
                            onChangeText={onChangeZipCode}
                            value={zipCode}
                            onBlur={onSearchZipCode}
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                        <View style={[PageStyles.inputWrapper, { width: '48%' }]}>
                          <MTextInput
                            label={locale.t('paymentData.form.state')}
                            onChangeText={setState}
                            value={state}
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                      </View>
                      <View style={[PageStyles.inputWrapper, { width: '46%' }]}>
                        <MTextInput
                          label={locale.t('paymentData.form.city')}
                          onChangeText={setCity}
                          value={city}
                          height={42}
                          borderRadius={14}
                        />
                      </View>
                    </View>
                    <View style={PageStyles.formRow}>
                      <View style={[PageStyles.inputWrapper, { width: '46%' }]}>
                        <MTextInput
                          label={locale.t('paymentData.form.street')}
                          onChangeText={setStreet}
                          value={street}
                          height={42}
                          borderRadius={14}
                        />
                      </View>
                      <View style={[PageStyles.formRow, { width: '46%' }]}>
                        <View style={[PageStyles.inputWrapper, { width: '48%' }]}>
                          <MTextInput
                            label={locale.t('paymentData.form.number')}
                            onChangeText={setNumber}
                            value={number}
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                        <View style={[PageStyles.inputWrapper, { width: '48%' }]}>
                          <MTextInput
                            label={locale.t('paymentData.form.complement')}
                            onChangeText={setComplement}
                            value={complement}
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </ScrollView>
          </View>
        </Paper>
      </Container>
    </>
  );
};

export default PaymentDataPage;
