import AsyncStorage from '@react-native-async-storage/async-storage';
import { base64Decode, base64Encode } from '@/utils/strUtils';

const isJson = (val: any) => typeof val === 'object';

export const LocalStorage = {
  save: async (key: string, value: any): Promise<void> => {
    if (isJson(value)) {
      const str = base64Encode(JSON.stringify(value));
      return AsyncStorage.setItem(key, str);
    }
    return AsyncStorage.setItem(key, value);
  },
  get: async (key: string): Promise<string | null> => {
    const value = await AsyncStorage.getItem(key);

    if (value) {
      return base64Decode(value);
    }

    return null;
  },
};
