import React from 'react';

import { ScrollView, Text, View } from 'react-native';
import { MFullWidthButton, MSelect, MTextInput } from 'menupass-ui';
import { Header } from '@/components/header';
import { Container } from '@/components/container';
import locale from '@/internationalization';
import { Paper } from '@/components/paper';
import { colors, dimensions, Images } from '@/consts';
import { ProgressBar } from '@/components/progressbar';
import { PaperColumns } from '@/styles/common/PaperColumns';
import { Subheader } from '@/components/subheader';
import { EntitiesPageTitle } from '@/components/entitiespagetitle';
import { PageStyles } from '@/styles/common/PageStyles';
import { Styles } from './ResponsibleCreatePage.styles';
import ResponsibleTabs from '@/components/responsibletabs/ResponsibleTabs';
import { ResponsibleCreatePageTypes } from './ResponsibleCreatePage.types';
import { useResponsibleCreate } from './ResponsibleCreatePage.functions';
import { ErrorHandler } from '@/components/errorhandler';
import { Routes } from '@/navigation/routes';
import { ResponsibleTypeSelect } from './ResponsibleCreatePage.consts';
import { ResponsibleRoles } from '@/types/responsibleRoles';

export const ResponsibleCreatePage = ({
  route: {
    params: { entity, id },
  },
}: ResponsibleCreatePageTypes) => {
  const {
    adminData,
    entityData,
    setPhone,
    phone,
    cpf,
    setCpf,
    emailError,
    setName,
    name,
    email,
    step1,
    step2,
    loading,
    setComplement,
    complement,
    setNumber,
    number,
    setCity,
    setStreet,
    street,
    setState,
    state,
    setZipCode,
    zipCode,
    city,
    setConfirmPassword,
    password,
    confirmPassword,
    setPassword,
    hasAPIError,
    APIErrorMessage,
    isEditing,
    responsibleType,
    setResponsibleType,
    goToStep1,
    goToStep2,
    emailChange,
    goBack,
    submit,
    checkIfEmailAlreadyExists,
    checkIfPhoneAlreadyExists,
    onSearchZipCode,
  } = useResponsibleCreate(entity, id || '');
  return (
    <>
      <Header username={adminData?.name} role={adminData?.role} />
      <Subheader
        role={adminData?.adminType?.type}
        entityName={entityData?.name}
        entityId={entity}
        activeScreen={Routes.ResponsibleProfiles}
      />
      <Container>
        <EntitiesPageTitle
          title={locale.t('responsibleCreate.title')}
          exportPdf={() => console.log(null)}
          exportCsv={() => console.log(null)}
        />
        <Paper disableInternalPadding bgColor="transparent" hasSubmenu>
          <ResponsibleTabs entityId={entity} create profiles={false} />
          <View style={[PaperColumns.tableContainer]}>
            <ScrollView>
              <View style={PaperColumns.container}>
                <View style={PaperColumns.column}>
                  <View style={PageStyles.imageWrapper}>
                    <img
                      src={Images.AdminCreateIcon}
                      alt={locale.t('imagesAlt.new')}
                    />
                  </View>
                  <Text style={PageStyles.subtitle}>
                    {locale.t('responsibleCreate.new')}
                  </Text>
                  <Text style={PageStyles.content}>
                    {locale.t('responsibleCreate.content')}
                  </Text>
                </View>
                <View style={PaperColumns.column}>
                  <View style={Styles.progressbarContainer}>
                    <ProgressBar
                      steps={2}
                      width={dimensions.paperWidth / 2 - 40}
                      step1={step1}
                      step2={step2}
                    />
                  </View>
                  {step1 && !step2 && (
                    <View style={PageStyles.subtitleHeader}>
                      <Text style={[PageStyles.subtitle, { marginBottom: 0 }]}>
                        {locale.t('responsibleCreate.basicInfo')}
                      </Text>
                      <View style={PageStyles.headerButtons}>
                        <View style={PageStyles.buttonsWrapper}>
                          <MFullWidthButton
                            label={locale.t('responsibleCreate.cancel')}
                            bgColor={colors.newEntity.entitiesBtn}
                            labelColor={colors.newEntity.entitiesBtnTitle}
                            onPress={goBack}
                            height={42}
                            fontSize={14}
                          />
                        </View>
                        <View style={PageStyles.buttonsWrapper}>
                          <MFullWidthButton
                            label={locale.t('responsibleCreate.next')}
                            disabled={
                              (name.trim() === '' ||
                                email.trim() === '' ||
                                cpf.trim() === '' ||
                                phone.trim() === '' ||
                                phone.length !== 15 ||
                                cpf.length !== 14 ||
                                loading ||
                                emailError ||
                                hasAPIError ||
                                password.trim() === '' ||
                                confirmPassword.trim() === '' ||
                                password.trim() !== confirmPassword.trim()) &&
                              !isEditing
                            }
                            onPress={goToStep2}
                            height={42}
                            fontSize={14}
                          />
                        </View>
                      </View>
                    </View>
                  )}
                  {step1 && step2 && (
                    <View style={PageStyles.subtitleHeader}>
                      <Text style={[PageStyles.subtitle, { marginBottom: 0 }]}>
                        {locale.t('responsibleCreate.othersInfo')}
                      </Text>
                      <View style={PageStyles.headerButtons}>
                        <View style={PageStyles.buttonsWrapper}>
                          <MFullWidthButton
                            label={locale.t('responsibleCreate.back')}
                            bgColor={colors.newEntity.entitiesBtn}
                            labelColor={colors.newEntity.entitiesBtnTitle}
                            onPress={goToStep1}
                            height={42}
                            fontSize={14}
                          />
                        </View>
                        <View style={PageStyles.buttonsWrapper}>
                          <MFullWidthButton
                            label={locale.t('responsibleCreate.register')}
                            disabled={
                              zipCode.trim() === '' ||
                              state.trim() === '' ||
                              street.trim() === '' ||
                              city.trim() === '' ||
                              number.trim() === '' ||
                              zipCode.length !== 9 ||
                              loading
                            }
                            onPress={submit}
                            height={42}
                            fontSize={14}
                          />
                        </View>
                      </View>
                    </View>
                  )}
                  {hasAPIError && <ErrorHandler message={APIErrorMessage} />}
                  <View style={Styles.inputContainer}>
                    {step1 && !step2 && (
                      <Text style={Styles.content}>
                        {locale.t('responsibleCreate.content1')}
                      </Text>
                    )}
                    {step1 && step2 && (
                      <Text style={Styles.content}>
                        {locale.t('responsibleCreate.othersContent')}
                      </Text>
                    )}
                    {step1 && !step2 && (
                      <>
                        <View style={PageStyles.formRow}>
                          <View
                            style={[PageStyles.inputWrapper, { width: '46%' }]}>
                            <MTextInput
                              label={locale.t('responsibleCreate.form.name')}
                              value={name}
                              onChangeText={setName}
                              height={42}
                              borderRadius={14}
                            />
                          </View>
                          <View
                            style={[PageStyles.inputWrapper, { width: '46%' }]}>
                            <MSelect
                              onValueChange={(value: string) =>
                                setResponsibleType(value as ResponsibleRoles)
                              }
                              selectedValue={responsibleType}
                              values={ResponsibleTypeSelect}
                              label={locale.t('responsibleCreate.form.role')}
                              height={42}
                              borderRadius={14}
                            />
                          </View>
                        </View>
                        <View style={PageStyles.formRow}>
                          <View
                            style={[PageStyles.inputWrapper, { width: '46%' }]}>
                            <MTextInput
                              label={locale.t('responsibleCreate.form.email')}
                              hasError={emailError}
                              value={email}
                              keyboardType="email-address"
                              onChangeText={emailChange}
                              onBlur={checkIfEmailAlreadyExists}
                              height={42}
                              borderRadius={14}
                            />
                          </View>
                          <View
                            style={[PageStyles.inputWrapper, { width: '46%' }]}>
                            <MTextInput
                              label={locale.t('responsibleCreate.form.cpf')}
                              mask="000.000.000-00"
                              value={cpf}
                              onChangeText={setCpf}
                              height={42}
                              borderRadius={14}
                            />
                          </View>
                        </View>
                        <View style={PageStyles.formRow}>
                          <View
                            style={[PageStyles.inputWrapper, { width: '46%' }]}>
                            <MTextInput
                              label={locale.t('responsibleCreate.form.phone')}
                              mask="(00) 00000-0000"
                              value={phone}
                              onChangeText={setPhone}
                              onBlur={checkIfPhoneAlreadyExists}
                              height={42}
                              borderRadius={14}
                            />
                          </View>
                        </View>
                        <View style={PageStyles.formRow}>
                          <View
                            style={[PageStyles.inputWrapper, { width: '46%' }]}>
                            <MTextInput
                              label={locale.t(
                                'responsibleCreate.form.password',
                              )}
                              secureTextEntry
                              value={password}
                              onChangeText={setPassword}
                              isPassword
                              height={42}
                              borderRadius={14}
                            />
                          </View>
                          <View
                            style={[PageStyles.inputWrapper, { width: '46%' }]}>
                            <MTextInput
                              label={locale.t(
                                'responsibleCreate.form.confirmPassword',
                              )}
                              secureTextEntry
                              value={confirmPassword}
                              onChangeText={setConfirmPassword}
                              isPassword
                              height={42}
                              borderRadius={14}
                            />
                          </View>
                        </View>
                      </>
                    )}
                    {step1 && step2 && (
                      <>
                        <View style={[PageStyles.formRow, { width: '100%' }]}>
                          <View
                            style={[PageStyles.inputWrapper, { width: '25%' }]}>
                            <MTextInput
                              label={locale.t('newEntity.form.cep')}
                              onChangeText={setZipCode}
                              value={zipCode}
                              mask="00000-000"
                              onBlur={onSearchZipCode}
                              height={42}
                              borderRadius={14}
                            />
                          </View>
                          <View
                            style={[PageStyles.inputWrapper, { width: '25%' }]}>
                            <MTextInput
                              label={locale.t('newEntity.form.state')}
                              onChangeText={setState}
                              value={state}
                              maxLength={2}
                              height={42}
                              borderRadius={14}
                            />
                          </View>
                          <View
                            style={[PageStyles.inputWrapper, { width: '40%' }]}>
                            <MTextInput
                              label={locale.t('newEntity.form.city')}
                              onChangeText={setCity}
                              value={city}
                              height={42}
                              borderRadius={14}
                            />
                          </View>
                        </View>
                        <View style={[PageStyles.formRow, { width: '100%' }]}>
                          <View
                            style={[PageStyles.inputWrapper, { width: '50%' }]}>
                            <MTextInput
                              label={locale.t('newEntity.form.street')}
                              onChangeText={setStreet}
                              value={street}
                              height={42}
                              borderRadius={14}
                            />
                          </View>
                          <View
                            style={[PageStyles.inputWrapper, { width: '20%' }]}>
                            <MTextInput
                              label={locale.t('newEntity.form.number')}
                              onChangeText={setNumber}
                              value={number}
                              height={42}
                              borderRadius={14}
                            />
                          </View>
                          <View
                            style={[PageStyles.inputWrapper, { width: '20%' }]}>
                            <MTextInput
                              label={locale.t('newEntity.form.complement')}
                              onChangeText={setComplement}
                              value={complement}
                              height={42}
                              borderRadius={14}
                            />
                          </View>
                        </View>
                      </>
                    )}
                  </View>
                </View>
              </View>
            </ScrollView>
          </View>
        </Paper>
      </Container>
    </>
  );
};

export default ResponsibleCreatePage;
