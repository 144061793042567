import React from 'react';
import { ScrollView, Text, View } from 'react-native';
import {
  MFullWidthButton, MRadioButton, MSelect, MTextInput,
} from 'menupass-ui';
import { Header } from '@/components/header';
import { Container } from '@/components/container';
import locale from '@/internationalization';
import { PageTitle } from '@/components/pagetitle';
import { Paper } from '@/components/paper';
import { PaymentTabs } from '@/components/paymenttabs';
import { PaperColumns } from '@/styles/common/PaperColumns';
import { Styles } from './paymentAddCard.style';
import { colors, Images } from '@/consts';
import { PageStyles } from '@/styles/common/PageStyles';
import { usePaymentAddCard } from './PaymentAddCard.functions';
import { ErrorHandler } from '@/components/errorhandler';
import { PaymentAddCardTypes } from './PaymentAddCard.types';
import { Flows } from '@/navigation/routes/enums/Flows';

export const PaymentAddCardPage = ({ route: { params } }: PaymentAddCardTypes) => {
  const {
    cardBrand,
    cardBrandSelect,
    adminData,
    cardNumber,
    cardValidation,
    cardHolder,
    securityCode,
    cardHolderNationalId,
    hasAPIError,
    APIErrorMessage,
    alreadyHaveCard,
    loading,
    forwardBtnText,
    nationalIdRadio,
    nationalIdMask,
    nationalIdHasError,
    onChangeCardNumber,
    submit,
    onChangeCardValidation,
    onChangeSecurityCode,
    onChangeCardHolderNationalId,
    onChangeCardBrand,
    goBackToCards,
    setCardHolder,
    onChangeNationalIdRadio,
  } = usePaymentAddCard(params?.flow || Flows.undefined);
  return (
    <>
      <Header username={adminData?.name} role={adminData?.role} />
      <Container>
        <PageTitle title={locale.t('paymentAddCard.pageTitle')} />
        <Paper disableInternalPadding bgColor="transparent">
          <PaymentTabs
            paymentCards={false}
            paymentAddCard
            paymentsData={false}
            payments={false}
          />
          <View style={PaperColumns.tableContainer}>
            <ScrollView>
              <View style={PaperColumns.container}>
                <View style={PaperColumns.column}>
                  <View style={PageStyles.imageWrapper}>
                    <img src={Images.PaymentDataIcon} alt={locale.t('imagesAlt.paymentData')} />
                  </View>
                  <Text style={PageStyles.subtitle}>{locale.t('paymentAddCard.subtitle')}</Text>
                  <Text style={PageStyles.content}>{locale.t('paymentAddCard.content')}</Text>
                </View>
                <View style={PaperColumns.column}>
                  <View style={PageStyles.subtitleHeader}>
                    <View />
                    <View style={PageStyles.headerButtons}>
                      <View style={PageStyles.buttonsWrapper}>
                        <MFullWidthButton
                          label={locale.t('paymentAddCard.cancel')}
                          bgColor={colors.newEntity.entitiesBtn}
                          labelColor={colors.newEntity.entitiesBtnTitle}
                          onPress={goBackToCards}
                          height={42}
                          fontSize={14}
                        />
                      </View>
                      <View style={PageStyles.buttonsWrapper}>
                        <MFullWidthButton
                          label={forwardBtnText}
                          disabled={
                            cardHolder.trim() === ''
                            || cardNumber.trim() === ''
                            || cardBrand.trim() === ''
                            || cardValidation.trim() === ''
                            || securityCode.trim() === ''
                            || cardHolderNationalId.trim() === ''
                            || nationalIdHasError
                            || loading
                          }
                          onPress={submit}
                          height={42}
                          fontSize={14}
                        />
                      </View>
                    </View>
                  </View>
                  {hasAPIError && (
                    <ErrorHandler message={APIErrorMessage} />
                  )}
                  {!alreadyHaveCard ? (
                    <View style={Styles.form}>
                      <Text style={PageStyles.subTitleForm}>{locale.t('paymentData.form.content')}</Text>
                      <View style={PageStyles.formRow}>
                        <View style={[PageStyles.inputWrapper, { width: '100%' }]}>
                          <MTextInput
                            label={locale.t('paymentAddCard.form.name')}
                            value={cardHolder}
                            onChangeText={setCardHolder}
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                      </View>
                      <View style={PageStyles.formRow}>
                        <View style={[PageStyles.inputWrapper, { width: '60%' }]}>
                          <MTextInput
                            label={locale.t('paymentAddCard.form.number')}
                            value={cardNumber}
                            onChangeText={onChangeCardNumber}
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                        <View style={[PageStyles.inputWrapper, { width: '36%' }]}>
                          <MSelect
                            label={locale.t('paymentAddCard.form.brand')}
                            values={cardBrandSelect}
                            selectedValue={cardBrand}
                            onValueChange={onChangeCardBrand}
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                      </View>
                      <View style={PageStyles.formRow}>
                        <View style={[PageStyles.inputWrapper, { width: '46%' }]}>
                          <MTextInput
                            label={locale.t('paymentAddCard.form.expirationDate')}
                            value={cardValidation}
                            onChangeText={onChangeCardValidation}
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                        <View style={[PageStyles.inputWrapper, { width: '46%' }]}>
                          <MTextInput
                            label={locale.t('paymentAddCard.form.securityCode')}
                            value={securityCode}
                            onChangeText={onChangeSecurityCode}
                            secureTextEntry
                            isPassword
                            height={42}
                            borderRadius={14}
                          />
                        </View>
                      </View>
                      <View style={PageStyles.formRow}>
                        <View style={[PageStyles.inputWrapper, { width: '60%' }]}>
                          <MTextInput
                            label={locale.t('paymentAddCard.form.holderNationalId')}
                            value={cardHolderNationalId}
                            onChangeText={onChangeCardHolderNationalId}
                            height={42}
                            borderRadius={14}
                            hasError={nationalIdHasError}
                          />
                        </View>
                        <View style={[PageStyles.inputWrapper, { width: '26%' }]}>
                          <View style={Styles.radioWrapper}>
                            <MRadioButton
                              items={nationalIdRadio.current}
                              onChangeValue={onChangeNationalIdRadio}
                              flexDirection="row"
                              side="left"
                              value={nationalIdMask}
                              style={Styles.radio}
                            />
                          </View>
                        </View>
                      </View>
                    </View>
                  ) : (
                    <ErrorHandler message={locale.t('paymentAddCard.alreadyHaveCard')} />
                  )}
                </View>
              </View>
            </ScrollView>
          </View>
        </Paper>
      </Container>
    </>
  );
};

export default PaymentAddCardPage;
