import React from 'react';

import { ScrollView, Text, View } from 'react-native';
import { MFullWidthButton, MTextInput } from 'menupass-ui';
import { Header } from '@/components/header';
import { Container } from '@/components/container';
import locale from '@/internationalization';
import { Paper } from '@/components/paper';
import { colors } from '@/consts';
import { PaperColumns } from '@/styles/common/PaperColumns';
import { Subheader } from '@/components/subheader';
import { EntitiesPageTitle } from '@/components/entitiespagetitle';
import { PageStyles } from '@/styles/common/PageStyles';
import { Styles } from './ManualRechargeCreatePage.styles';
import { FinanceTabs } from '@/components/financetabs';
import { ManualRechargeCreatePageTypes } from './ManualRechargeCreatePage.types';
import { useManualRechargeCreate } from './ManualRechargeCreatePage.functions';
import { Routes } from '@/navigation/routes';
import { SearchableInput } from '@/components/searchableinput/SearchableInput';

export const ManualRechargeCreatePage = ({
  route: { params: { entity } },
}: ManualRechargeCreatePageTypes) => {
  const {
    adminData,
    entityData,
    client,
    disabled,
    clientsSearchList,
    setClient,
    setClientId,
    responsible,
    setResponsible,
    value,
    setValue,
    recharge,
    clientId,
    cancel,
  } = useManualRechargeCreate(entity);
  return (
    <>
      <Header username={adminData?.name} role={adminData?.role} />
      <Subheader
        entityName={entityData?.name}
        entityId={entity}
        activeScreen={Routes.FinanceExtracts}
      />
      <Container>
        <EntitiesPageTitle
          title={locale.t('manualRechargeCreate.title')}
          exportPdf={() => console.log(null)}
          exportCsv={() => console.log(null)}
        />
        <Paper disableInternalPadding bgColor="transparent" hasSubmenu>
          <FinanceTabs entityId={entity} manualRecharge extract={false} />
          <View style={[PaperColumns.tableContainer]}>
            <ScrollView>
              <View style={PaperColumns.container}>
                <View style={PaperColumns.column}>
                  <View style={PageStyles.imageWrapper} />
                  <Text style={PageStyles.subtitle}>{locale.t('manualRechargeCreate.new')}</Text>
                  <Text style={PageStyles.content}>{locale.t('manualRechargeCreate.content')}</Text>
                </View>
                <View style={PaperColumns.column}>
                  <View style={PageStyles.subtitleHeader}>
                    <View />
                    <View style={PageStyles.headerButtons}>
                      <View style={PageStyles.buttonsWrapper}>
                        <MFullWidthButton
                          label={locale.t('manualRechargeCreate.cancel')}
                          bgColor={colors.newEntity.entitiesBtn}
                          labelColor={colors.newEntity.entitiesBtnTitle}
                          height={42}
                          onPress={cancel}
                          fontSize={14}
                        />
                      </View>
                      <View style={PageStyles.buttonsWrapper}>
                        <MFullWidthButton
                          label={locale.t('manualRechargeCreate.save')}
                          height={42}
                          fontSize={14}
                          onPress={recharge}
                          disabled={disabled || responsible === '' || client === '' || clientId === '' || value === 0 || value === null}
                        />
                      </View>
                    </View>
                  </View>
                  <View style={Styles.inputContainer}>
                    <Text style={Styles.content}>{`${locale.t('manualRechargeCreate.content1')} ${responsible}`}</Text>
                    <View style={PageStyles.formRow}>
                      <View style={[PageStyles.inputWrapper, { width: '46%' }]}>
                        <SearchableInput
                          value={client}
                          onChange={(inputValue) => setClient(inputValue)}
                          label={locale.t('manualRechargeCreate.form.clients')}
                          data={clientsSearchList}
                          onSelect={(item) => {
                            setClientId(item.value);
                            setClient(item.label);
                            setResponsible(item.metadata);
                          }}
                        />
                      </View>
                      <View style={[PageStyles.inputWrapper, { width: '46%' }]}>
                        <MTextInput
                          label={locale.t('manualRechargeCreate.form.value')}
                          value={value}
                          prefix="R$ "
                          onChangeValue={setValue}
                          isCurrency
                          height={42}
                          borderRadius={14}
                        />
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </ScrollView>
          </View>
        </Paper>
      </Container>
    </>
  );
};

export default ManualRechargeCreatePage;
