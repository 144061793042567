import fetch from '../fetch';
import { LocalStorage } from '@/services/localStorage';
import { ADMIN } from '@/consts/storageKeys';
import { UpdateAdminDto, UpdateAdminPasswordDto, UpdateAdminPaymentAddressDto } from './dto';

export const getAdminId = async () => {
  const adminStr = await LocalStorage.get(ADMIN);
  const admin = JSON.parse(adminStr as string);

  return admin.id;
};
export const getAdmin = (id: string) => fetch(`/entity/admin/${id}`).then((result) => result.json());
export const updateAdminPaymentAddress = (dto: UpdateAdminPaymentAddressDto) => fetch(`/entity/admin/payment-address/${dto.id}`, {
  method: 'PUT',
  body: JSON.stringify(dto),
});
export const updateAdmin = (dto: UpdateAdminDto) => fetch(`/entity/admin/${dto.id}`, {
  method: 'PUT',
  body: JSON.stringify(dto),
});

export const updatePassword = (dto: UpdateAdminPasswordDto) => fetch('/entity/admin/recovery-password', {
  method: 'POST',
  body: JSON.stringify(dto),
});
