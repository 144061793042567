import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { Routes } from '../routes';
import { ManualRechargeCreatePage } from '../../pages/manualrechargecreate';
import { FinanceRouteParams } from '../routes/RoutesParams';
import { FinanceExtractsPage } from '@/pages/financeextracs';

const Stack = createStackNavigator<FinanceRouteParams>();

export const Finance = () => (
  <Stack.Navigator>
    <Stack.Screen
      name={Routes.FinanceExtracts}
      component={FinanceExtractsPage}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={Routes.FinanceManualRecharge}
      component={ManualRechargeCreatePage}
      options={{ headerShown: false }}
    />
  </Stack.Navigator>
);

export default Finance;
