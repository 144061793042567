import { NavigationProp, useNavigation } from '@react-navigation/native';
import { Routes } from '../index';
import {
  AdministrationRouteParams,
  CategoryRouteParams,
  ClientRouteParams,
  FinanceRouteParams,
  InternalRouteParams,
  InvitationsRouteParams,
  ProductRouteParams,
  ResponsibleRouteParams,
  SectorRouteParams,
  SelectedEntityParams, SystemRouteParams, TotemRouteParams,
} from '../RoutesParams';

export type InternalEntityPages =
  | Routes.System
  | Routes.SystemConfig
  | Routes.periodCreate
  | Routes.periodList
  | Routes.Administration
  | Routes.Totem
  | Routes.Responsible
  | Routes.Client
  | Routes.Product
  | Routes.Category
  | Routes.Invitations
  | Routes.AdministrationProfiles
  | Routes.AdministrationCreate
  | Routes.Finance
  | Routes.ResponsibleProfiles
  | Routes.ResponsibleCreate
  | Routes.ClientProfiles
  | Routes.ClientCreate
  | Routes.CategoryList
  | Routes.CategoryCreate
  | Routes.ProductList
  | Routes.ProductCreate
  | Routes.FinanceExtracts
  | Routes.FinanceManualRecharge
  | Routes.InvitationsCreate
  | Routes.InvitationsList
  | Routes.InvitationsCreateGeneral
  | Routes.TotemProfiles
  | Routes.sector
  | Routes.sectorCreate
  | Routes.sectorList;

export type InternalRouteProps = InternalRouteParams &
  AdministrationRouteParams &
  TotemRouteParams &
  ResponsibleRouteParams &
  ClientRouteParams &
  CategoryRouteParams &
  ProductRouteParams &
  FinanceRouteParams &
  SectorRouteParams &
  SystemRouteParams &
  InvitationsRouteParams;

export const useNavigateEntityInternalPages = () => {
  const navigation = useNavigation<
    NavigationProp<InternalRouteProps, InternalEntityPages>
  >();

  const goToEntityPage = (
    route: InternalEntityPages,
    params: SelectedEntityParams,
  ) => {
    navigation.navigate(route, params);
  };

  return { goToEntityPage };
};
