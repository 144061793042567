import React from 'react';
import { InputNumber } from 'antd';
import { View, Text } from 'react-native';
import { PercentageInputTypes } from './PercentageInput.types';
import { styles } from './PercentageInput.styles';

const PercentageInput = ({
  onChange,
  label,
  discount,
}: PercentageInputTypes) => (
    <View>
      <Text style={styles.textLabel}>{label}</Text>
      <View style={styles.inputWarpper}>
        <InputNumber
          style={{
            width: '273px',
            height: '40px',
            border: 'none',
            boxShadow: 'none',
          }}
          value={discount}
          min={0}
          max={100}
          bordered={false}
          formatter={value => `${value}%`}
          parser={value => parseFloat(value?.replace('%', '') || '0')}
          onChange={onChange}
        />
      </View>
    </View>
  );

export default PercentageInput;
