export enum Paths {
  Login = 'login',
  Register = 'register/step-1',
  Register2 = 'register/step-2',
  Home = 'home',
  NewEntity = 'entity-create',
  PaymentHistory = 'payment-history',
  PaymentData = 'data',
  PaymentCards = 'cards',
  PaymentAddCard = 'cards-create',
  Profile = 'profile',
  PlanHistory = 'plans-history',
  PlansAdd = 'plans-create',
  AdministrationProfiles = 'admin-profiles',
  AdministrationCreate = 'admin-create',
  ResponsibleProfiles = 'responsible-profiles',
  ResponsibleCreate = 'responsible-create',
  ClientProfiles = 'client-profiles',
  ClientCreate = 'client-create',
  ProductList = 'product-profiles',
  ProductCreate = 'product-create',
  CategoryList = 'category-profiles',
  CategoryCreate = 'category-create',
  System = 'system',
  SystemConfig = 'system-config',
  periodCreate = 'period-create',
  periodList = 'period-list',
  sector = 'sector',
  sectorCreate = 'sector-create',
  sectorList = 'sector-list',
  FinanceExtracts = 'finance-extracts',
  FinanceManualRecharge = 'finance-manual-recharge',
  RecoveryPassword = 'recovery-password',
  RecoveryPassword2 = 'recovery-password/step-2',
  InvitationsList = 'invitations-list',
  InvitationsCreate = 'invitations-create',
  InvitationsCreateGeneral = 'invitations-create-general',
  TotemProfiles = 'totem-profiles',
  TotemProfilesCreate = 'totem-create'
}

export enum Routes {
  External = 'ex',
  Internal = 'in',
  Login = 'Login',
  Register = 'Register',
  Register2 = 'Register2',
  RecoveryPassword = 'RecoveryPassword',
  RecoveryPassword2 = 'RecoveryPassword2',
  Home = 'Home',
  NewEntity = 'NewEntity',
  Payment = 'payment',
  PaymentHistory = 'PaymentHistory',
  PaymentData = 'PaymentData',
  PaymentCards = 'PaymentCards',
  PaymentAddCard = 'PaymentAddCard',
  Profile = 'Profile',
  Plan = 'plan',
  PlanHistory = 'PlanHistory',
  PlanAdd = 'PlanAdd',
  Administration = 'administration',
  AdministrationProfiles = 'AdministrationProfiles',
  TotemProfiles = 'TotemProfiles',
  TotemProfilesCreate = 'TotemProCreate',
  Totem = 'totem',
  AdministrationCreate = 'AdministrationCreate',
  Responsible = 'responsible',
  ResponsibleProfiles = 'ResponsibleProfiles',
  ResponsibleCreate = 'ResponsibleCreate',
  Client = 'client',
  ClientProfiles = 'ClientProfiles',
  ClientCreate = 'ClientCreate',
  Product = 'product',
  ProductList = 'ProductList',
  ProductCreate = 'ProductCreate',
  Category = 'category',
  CategoryList = 'CategoryList',
  CategoryCreate = 'CategoryCreate',
  SystemConfig = 'system-config',
  System = 'system',
  periodCreate = 'periodCreate',
  periodList = 'periodList',
  sector = 'sector',
  sectorCreate = 'sectorCreate',
  sectorList = 'sectorList',
  Finance = 'finance',
  FinanceExtracts = 'FinanceExtracts',
  FinanceManualRecharge = 'FinanceManualRecharge',
  Invitations = 'Invitations',
  InvitationsList = 'InvitationsList',
  InvitationsCreate = 'InvitationsCreate',
  InvitationsCreateGeneral = 'InvitationsCreateGeneral',
}

const screens: Partial<{ [key in Routes]: Paths }> = {
  [Routes.Login]: Paths.Login,
  [Routes.Register]: Paths.Register,
  [Routes.Register2]: Paths.Register2,
  [Routes.Home]: Paths.Home,
  [Routes.NewEntity]: Paths.NewEntity,
  [Routes.PaymentHistory]: Paths.PaymentHistory,
  [Routes.PaymentData]: Paths.PaymentData,
  [Routes.PaymentCards]: Paths.PaymentCards,
  [Routes.PaymentAddCard]: Paths.PaymentAddCard,
  [Routes.Profile]: Paths.Profile,
  [Routes.PlanHistory]: Paths.PlanHistory,
  [Routes.PlanAdd]: Paths.PlansAdd,
  [Routes.AdministrationProfiles]: Paths.AdministrationProfiles,
  [Routes.AdministrationCreate]: Paths.AdministrationCreate,
  [Routes.ResponsibleProfiles]: Paths.ResponsibleProfiles,
  [Routes.ResponsibleCreate]: Paths.ResponsibleCreate,
  [Routes.ClientProfiles]: Paths.ClientProfiles,
  [Routes.ClientCreate]: Paths.ClientCreate,
  [Routes.ProductList]: Paths.ProductList,
  [Routes.ProductCreate]: Paths.ProductCreate,
  [Routes.CategoryList]: Paths.CategoryList,
  [Routes.CategoryCreate]: Paths.CategoryCreate,
  [Routes.System]: Paths.System,
  [Routes.SystemConfig]: Paths.SystemConfig,
  [Routes.periodCreate]: Paths.periodCreate,
  [Routes.periodList]: Paths.periodList,
  [Routes.FinanceExtracts]: Paths.FinanceExtracts,
  [Routes.FinanceManualRecharge]: Paths.FinanceManualRecharge,
  [Routes.RecoveryPassword]: Paths.RecoveryPassword,
  [Routes.RecoveryPassword2]: Paths.RecoveryPassword2,
  [Routes.InvitationsList]: Paths.InvitationsList,
  [Routes.InvitationsCreate]: Paths.InvitationsCreate,
  [Routes.InvitationsCreateGeneral]: Paths.InvitationsCreateGeneral,
  [Routes.TotemProfiles]: Paths.TotemProfiles,
  [Routes.TotemProfilesCreate]: Paths.TotemProfilesCreate,
  [Routes.sectorCreate]: Paths.sectorCreate,
  [Routes.sectorList]: Paths.sectorList,
};

export const config = {
  screens,
};
