import React from 'react';
import { TouchableOpacity, View, Text } from 'react-native';
import { Datatable } from '@/components/datatable';
import { FinanceTabs } from '@/components/financetabs';
import { Paper } from '@/components/paper';
import { Header } from '@/components/header';
import { Container } from '@/components/container';
import { EntitiesPageTitle } from '@/components/entitiespagetitle';
import locale from '@/internationalization/';
import { useFinancExtracts } from './FinanceExtractsPage.functions';
import { Subheader } from '@/components/subheader';
import { Routes } from '@/navigation/routes';
import {
  financeExtractsPageHeader,
  financeExtractOrderProductPageHeader,
  financeActiveInactive,
} from './FinanceExtractsPage.consts';
import { FinanceExtractsPageStyle } from './FinanceExtractsPage.styles';
import { Images, colors } from '@/consts';
import { FinanceExtractPageTypes } from './FinanceExtractsPage.types';
import { SearchableInput } from '@/components/searchableinput/SearchableInput';
import { ModalLimit } from '@/components/modalLimit';
import Select from '@/components/select/Select';
import { Switch } from '@/components/switch/Switch';

export const FinanceExtractsPage = ({
  route: {
    params: { entity },
  },
}: FinanceExtractPageTypes) => {
  const {
    entityData,
    adminData,
    onShow,
    listItems,
    loading,
    extracts,
    user,
    userSearchList,
    virtualCBalance,
    onShowDetailsVC,
    limit,
    onShowLimitsDays,
    isActiveInactive,
    isActiveRecurrentRecharge,
    client,
    loadingUpdate,
    updateVirtualCard,
    setUser,
    returnlistItems,
    returnOrdersUser,
    setOnShowDetailsVC,
    close,
    setIsActiveInactive,
  } = useFinancExtracts(entity);

  return (
    <>
      <Header username={adminData?.name} role={adminData?.role} />
      <Subheader
        role={adminData?.adminType?.type}
        entityName={entityData?.name}
        entityId={entity}
        activeScreen={Routes.FinanceExtracts}
      />
      <Container>
        <EntitiesPageTitle
          title={locale.t('finance.title')}
          exportPdf={() => console.log(null)}
          exportCsv={() => console.log(null)}
        />
        <Paper disableInternalPadding bgColor="transparent" hasSubmenu>
          <FinanceTabs entityId={entity} extract manualRecharge={false} />
          {!onShow && (
            <>
              <View style={FinanceExtractsPageStyle.container}>
                <View style={FinanceExtractsPageStyle.containerHeader}>
                  {client && (
                    <View style={FinanceExtractsPageStyle.containerSwitch}>
                      <Text style={FinanceExtractsPageStyle.label}>
                        {locale.t('finance.recurrentRecharge')}
                      </Text>
                      <Switch
                        backgroundInactive={colors.datatableBody.switchDisabled}
                        backgroundActive={colors.datatableBody.switchEnabled}
                        circleColorInactive={colors.datatableBody.thumb}
                        circleColorActive={colors.datatableBody.thumb}
                        onValueChange={updateVirtualCard}
                        value={isActiveRecurrentRecharge}
                        disabled={loadingUpdate}
                      />
                    </View>
                  )}
                  <View style={FinanceExtractsPageStyle.InputWarpper}>
                    <SearchableInput
                      label={locale.t('finance.search')}
                      value={user}
                      onChange={inputValue => setUser(inputValue)}
                      data={userSearchList}
                      onSelect={item => {
                        returnOrdersUser(item.value);
                        setUser(item.label);
                      }}
                    />
                  </View>
                  <Select
                    label={locale.t('finance.status')}
                    onValueChange={(value: string) =>
                      setIsActiveInactive(value as any)
                    }
                    selectedValue={isActiveInactive}
                    values={financeActiveInactive}
                    height={42}
                    borderRadius={14}
                  />
                  <View style={FinanceExtractsPageStyle.containerBalance}>
                    <Text style={FinanceExtractsPageStyle.labelBalance}>
                      {locale.t('finance.cardBalance')}
                    </Text>
                    <View style={FinanceExtractsPageStyle.containerText}>
                      <Text style={FinanceExtractsPageStyle.textBalance}>
                        {virtualCBalance}
                      </Text>
                    </View>
                  </View>
                  <View style={FinanceExtractsPageStyle.containerVC}>
                    <Text style={FinanceExtractsPageStyle.labelVC}>
                      {locale.t('finance.cardBalanceDetails')}
                    </Text>
                    <TouchableOpacity
                      style={FinanceExtractsPageStyle.containerTextVC}
                      onPress={() => setOnShowDetailsVC(true)}>
                      <Text style={FinanceExtractsPageStyle.textVC}>
                        {locale.t('finance.cardBalanceDetailsMore')}
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
                <View style={FinanceExtractsPageStyle.databaseWarpper}>
                  <Datatable
                    headers={financeExtractsPageHeader}
                    data={extracts}
                    onDetalhes={returnlistItems}
                    searchFields={['type']}
                    loading={loading}
                  />
                </View>
              </View>
            </>
          )}
          {onShow && (
            <View style={FinanceExtractsPageStyle.container}>
              <TouchableOpacity
                style={FinanceExtractsPageStyle.modal}
                onPress={() => close()}>
                <img
                  src={Images.CloseCross}
                  alt={locale.t('imagesAlt.ClosseCross')}
                />
              </TouchableOpacity>
              <Datatable
                headers={financeExtractOrderProductPageHeader}
                data={listItems}
                searchFields={['Produto']}
                loading={loading}
              />
            </View>
          )}
        </Paper>

        {onShowDetailsVC && (
          <ModalLimit
            limit={limit}
            onShowLimitsDays={onShowLimitsDays}
            close={setOnShowDetailsVC}
          />
        )}
      </Container>
    </>
  );
};
