/* eslint-disable */
import IMask from 'imask';
import i18n from "i18next";

export const getLastUrlPiece = (): string => {
  const location = window.location.pathname.split('/');
  const removeQueryParams = location[location.length - 1].split('?');

  return removeQueryParams[0];
};
export const fullNameIsValid = (fullName: string): boolean => {
  return fullName.trim().split(/\s+/).length >= 2;
}
export const emailIsValid = (email: string): boolean => {
  const regex = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
  return !regex.test(email);
};
export const base64Encode = (str: string) => btoa(str);
export const base64Decode = (str: string) => atob(str);
export const phoNumberRemoveMask = (phone: string) => phone.replace(/\(|\)| |\-/g, '');
export const cpfRemoveMask = (cpf: string) => cpf.replace(/[^0-9]/g, "");
export const cnpjRemoveMask = (cnpj: string) => cnpj.replace(/\.|\/|-/g, "");
export const phoNumber = (phone: string) => phone;
export const phoneNumberRemoveDDI = (phone: string) => phone.substr(3, phone.length);
export const addMask = (data: string, mask: string) => {
  const maskCreator = IMask.createMask({ mask });
  return maskCreator.resolve(data);
};
export const currencyFormat = (data: string): number => {
  if (data) {
    if (!data.includes(',')) {
      return parseInt(`${data.toString()}00`.replace(/R\$| |\,|\.|/g, ''), 10)
    }

    return parseInt(data.toString().replace(/R\$| |\,|\.|/g, ''), 10);
  }
  return 0;
};

export const currencyFormatToString = (data: number, separator: string): string => {
  if (data) {
    // eslint-disable-next-line
    let arrValue = data.toString().replace(/R$| |\,|\.|/g, '').split('');
    if (arrValue.length < 3) {
      const newArrValue: any = [];
      newArrValue.push('0');
      arrValue.forEach((value) => {
        newArrValue.push(value);
      });
      arrValue = newArrValue;
    }
    arrValue.splice(arrValue.length - 2, 0, separator);
    return arrValue.join('');
  }
  return '';
};

export const currencyFormatFloat = (data: number): number => {
  if (data) {
    // eslint-disable-next-line
    let arrValue = data.toString().replace(/R$| |\,|\.|/g, '').split('');
    if (arrValue.length < 3) {
      const newArrValue: any = [];
      newArrValue.push('0');
      arrValue.forEach((value) => {
        newArrValue.push(value);
      });
      arrValue = newArrValue;
    }
    arrValue.splice(arrValue.length - 2, 0, '.');
    const numberFormatted = arrValue.join('');

    return parseFloat(numberFormatted);
  }
  return 0;
};

export const floatToInt = (data: number): number => {

  return parseInt(data.toString().replace('.', '') , 10);
};

export const stringToBoolean = (data: string): boolean => {
  const dataNumber = parseInt(data, 10);

  return dataNumber === 1;
};
export const applyPhoneNumberMask = (val: string) => {
  if (val) {
    const phoneMask = IMask.createMask({ mask: '(00) 00000-0000' });
    return phoneMask.resolve(val.replace(/\+55/g, ''));
  }
  return '';
}
export const applyZipCodeMask = (val: string) => {
  if (val) {
    const zip = IMask.createMask({ mask: '00000-000' });
    return zip.resolve(val.replace(/-/g, ''));
  }
  return '';
}

export const genAuthCode = () => {
  const code = Math.round(Date.now() / 1000).toString();
  return code.substr(6, code.length);
}

export const genEmailCode = () => {
  const code = Math.round(Date.now() / 1000).toString();
  return code.substr(4, code.length);
}

export const removeMenuPassDomainFromEmailCode = (email: string) => {
  return email.replace(/@menupass.com.br/g, '');
}

export const CNPJValidation = (value: string): boolean => {
  if (!value) return false;

  const validTypes =
    typeof value === 'string' ||
    Number.isInteger(value) ||
    Array.isArray(value);

  if (!validTypes) return false;

  const match = value.toString().match(/\d/g);
  const numbers = Array.isArray(match) ? match.map(Number) : [];

  if (numbers.length !== 14) return false;

  const items = numbers;
  if (items.length === 1) return false;

  const calc = (x: number) => {
    const slice = numbers.slice(0, x);
    let factor = x - 7;
    let sum = 0;

    for (let i = x; i >= 1; i--) {
      const n = slice[x - i];
      sum += n * factor--;
      if (factor < 2) factor = 9;
    }

    const result = 11 - (sum % 11);

    return result > 9 ? 0 : result;
  };

  const digits = numbers.slice(12);

  const digit0 = calc(12);
  if (digit0 !== digits[0]) return false;

  const digit1 = calc(13);
  return digit1 === digits[1];
};

export const CPFValidation = (cpf: string): boolean => {
  cpf = cpf.replace(/[\s.-]*/gim, '');
  if (
    !cpf ||
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  ) {
    return false;
  }
  let soma = 0;
  let resto;
  for (let i = 1; i <= 9; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10))) return false;
  soma = 0;
  for (let i = 1; i <= 10; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(10, 11))) return false;
  return true;
};

export const generateHash = (value: string) => {
  return btoa(value);
}

export const encodeRecoveryPassword = (code: string, phone: string) => {
  return generateHash(`${generateHash(code)}:${generateHash(phone)}`);
}

export const decodeRecoveryPassword = (hash: string) => {
  const values = atob(hash);
  const codeAndPhone = values.split(':');

  return {
    code: atob(codeAndPhone[0]),
    phone: atob(codeAndPhone[1]),
  }
}

export const roleTranslator = (role: string) => {
  switch (role) {
    case 'client':
      return i18n.t('clientRole');
    case 'admin':
      return i18n.t('adminRole');
    case 'admin_client':
      return i18n.t('adminClientRole');
    case 'system_admin':
      return i18n.t('systemAdminRole');
    case 'staff':
      return i18n.t('staffRole');
  }
}
